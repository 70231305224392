import {
  UPLOAD_MEDIA_SUCCESS,
  LAUNCH_ADS_SUCCESS,
  LAUNCH_ADS_FAILED,
  UPLOAD_MEDIA_FAILED,
  FETCH_PROJECT_MEDIA_FAILED,
  GET_EXTERNAL_PROJECT_FAILED,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
  IMPORT_PROJECT_SUCCESS,
  IMPORT_PROJECT_FAILED,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILED,
  UPLOAD_MEDIA,
  GRANT_PERMISSIONS_SUCCESS,
  GRANT_PERMISSIONS_FAILED,
  GET_ACCOUNT_MEDIA_FAILED,
  GET_ACCOUNT_AUDIENCES_FAILED,
  IMPORT_PROJECT,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILED,
  UPLOAD_PROGRESS,
  APP_INIT,
  LOAD_IMAGE_FAILED,
  GRANT_CUSTOM_AUDIENCES,
  LAUNCH_GOOGLE_ADS_SUCCESS,
  LAUNCH_GOOGLE_ADS_FAILED,
  GET_FACEBOOK_COMPLETIONS_FAILED,
  CREATE_SESSION_FAILED,
  STOP_JOB_SUCCESS,
  STOP_JOB_FAILED,
  DELETE_SESSIONS_SUCCESS,
  FETCH_SESSIONS_FAILED,
  LAUNCH_BUDGET_OPT_FAILED,
  LAUNCH_BUDGET_OPT_SUCCESS,
  UPDATE_AUTO_SCALING_SETTING_SUCCESS,
  UPDATE_AUTO_SCALING_SETTING_FAILED,
} from 'containers/App/constants';
import { DISMISS_ALERT } from './constants';

import { ERROR, INFO, SUCCESS } from './severities';

export const initialState = {
  open: false,
  message: '',
  severity: INFO,
  inProgress: false,
  progress: 0,
  label: '',
};

const pretify = type =>
  type
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_INIT:
      return initialState;

    case UPDATE_AUTO_SCALING_SETTING_SUCCESS:
    case LAUNCH_BUDGET_OPT_SUCCESS:
    case DELETE_SESSIONS_SUCCESS:
    case LAUNCH_GOOGLE_ADS_SUCCESS:
    case UPLOAD_VIDEO_SUCCESS:
    case GRANT_PERMISSIONS_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
    case IMPORT_PROJECT_SUCCESS:
    case DELETE_MEDIA_SUCCESS:
    case UPLOAD_MEDIA_SUCCESS:
    case LAUNCH_ADS_SUCCESS:
    case STOP_JOB_SUCCESS:
      return {
        severity: SUCCESS,
        open: true,
        message: payload.message || pretify(type),
      };

    case UPDATE_AUTO_SCALING_SETTING_FAILED:
    case LAUNCH_BUDGET_OPT_FAILED:
    case FETCH_SESSIONS_FAILED:
    case CREATE_SESSION_FAILED:
    case GET_FACEBOOK_COMPLETIONS_FAILED:
    case LAUNCH_GOOGLE_ADS_FAILED:
    case LOAD_IMAGE_FAILED:
    case UPLOAD_VIDEO_FAILED:
    case GET_ACCOUNT_AUDIENCES_FAILED:
    case GET_ACCOUNT_MEDIA_FAILED:
    case GRANT_PERMISSIONS_FAILED:
    case UPDATE_PROJECT_FAILED:
    case IMPORT_PROJECT_FAILED:
    case DELETE_MEDIA_FAILED:
    case GET_EXTERNAL_PROJECT_FAILED:
    case FETCH_PROJECT_MEDIA_FAILED:
    case UPLOAD_MEDIA_FAILED:
    case LAUNCH_ADS_FAILED:
    case STOP_JOB_FAILED:
      return {
        severity: ERROR,
        open: true,
        message: payload.error.message || pretify(type),
        description: payload.error.description,
      };

    case GRANT_CUSTOM_AUDIENCES:
    case IMPORT_PROJECT:
    case UPLOAD_MEDIA:
      return {
        severity: INFO,
        open: true,
        message: payload.message || pretify(type),
      };

    case UPLOAD_PROGRESS: {
      const { progress, name } = payload;

      return { ...state, inProgress: progress < 100, progress, label: name };
    }

    case DISMISS_ALERT:
      return { ...state, open: false };

    default:
      return state;
  }
};

export const getAlerts = state => state.alerts;
export const getAlertsMessage = state => getAlerts(state).message;
export const getAlertsDescription = state => getAlerts(state).description;
export const getAlertsSeverity = state => getAlerts(state).severity;
export const isOpen = state => getAlerts(state).open;

export const isInProgress = state => getAlerts(state).inProgress;
export const getProgress = state => getAlerts(state).progress;
export const getLabel = state => getAlerts(state).label;
