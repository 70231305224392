import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  DELETE_MEDIA,
  FETCH_CUSTOM_AUDIENCES,
  FETCH_PROJECTS,
  GET_ACCOUNT_AUDIENCES,
  GRANT_PERMISSIONS,
  IMPORT_PROJECT,
  LAUNCH_ADS,
  UPDATE_PROJECT,
  UPLOAD_MEDIA,
  AD_ACCOUNT_SELECTED,
  UPLOAD_VIDEO,
  FETCH_PROJECT_JOBS,
  START_JOBS_REFRESH,
  GRANT_CUSTOM_AUDIENCES,
  LAUNCH_GOOGLE_ADS,
  DISPLAY_ARCHIVED_PROJECTS,
  GET_FACEBOOK_COMPLETIONS,
  FETCH_SESSIONS,
  CREATE_SESSION,
  DELETE_SESSIONS,
  GET_PROJECT_FB_ADS_ACCOUNTS,
  PROJECT_SELECTED,
  GET_PROJECT_FB_PAGES,
  FETCH_PROJECT_MEDIA_SUCCESS,
  STOP_JOB,
  IMPORT_JOB_DATA,
  FORM_SELECTED,
  LAUNCH_BUDGET_OPT,
  ITERATION_UPDATED,
  FETCH_SESSIONS_FAILED,
  FETCH_SESSIONS_SUCCESS,
  GET_PROJECT_AUTO_SCALING_SETTING,
  UPDATE_AUTO_SCALING_SETTING,
} from 'containers/App/constants';
import handleFetchProjects from './sagas/fetch-projects';
import handleLaunchAds from './sagas/launch-ads';
import handleUploadMedia from './sagas/upload-media';
import handleDeleteMedia from './sagas/delete-media';
import handleProjectImport from './sagas/import-project';
import handleUpdateProject from './sagas/update-project';
import handleGrantPermissions from './sagas/grant-permissions';
import handleFetchCustomAudiences from './sagas/fetch-audiences';
import handleGetAudiences from './sagas/get-account-audiences';
import handleUploadVideo from './sagas/upload-video';
import handleFetchProjectJobs from './sagas/fetch-project-jobs';
import handleJobsRefresh from './sagas/refresh-jobs';
import handleGrantCustomAudiences from './sagas/grant-custom-audiences';
import handleLaunch from './sagas/launch-google-ads';
import handleGetCompletions from './sagas/completions';
import handleFetchSessions from './sagas/sessions';
import handleCreateSession from './sagas/create-session';
import handleSessionsDelete from './sagas/delete-sessions';
import handleFetchFacebookAdsAccounts from './sagas/fetch-facebook-ads-accounts';
import handleFetchProjectFacebookPages from './sagas/fetch-project-facebook-pages';
import handleFetchProjectMedia from './sagas/fetch-project-media';
import handleAspectRatio from './sagas/aspect-ratio';
import handleStopJob from './sagas/stop-job';
import handleRouteChange from './sagas/route-change';
import handleStatus from './sagas/get-project-status';
import handleCompletionsBuffer from './sagas/buffer-completions';
import handleOptimizationLaunch from './sagas/launch-budget-optimization';
import handleGetAutoScalingSetting from './sagas/get-project-auto-scaling-setting';
import handleUpdateAutoScaling from './sagas/update-auto-scaling-setting';

// Root saga
export default function* rootSaga() {
  yield all([
    takeLatest(UPLOAD_MEDIA, handleUploadMedia),
    takeLatest(
      [
        FETCH_PROJECTS,
        DISPLAY_ARCHIVED_PROJECTS,
        FETCH_SESSIONS_FAILED,
        FETCH_SESSIONS_SUCCESS,
      ],
      handleFetchProjects,
    ),
    takeLatest(LAUNCH_ADS, handleLaunchAds),
    takeLatest(DELETE_MEDIA, handleDeleteMedia),
    takeLatest(IMPORT_PROJECT, handleProjectImport),
    takeLatest(UPDATE_PROJECT, handleUpdateProject),
    takeLatest(GRANT_PERMISSIONS, handleGrantPermissions),
    takeLatest([FETCH_CUSTOM_AUDIENCES], handleFetchCustomAudiences),
    takeLatest(
      [GET_ACCOUNT_AUDIENCES, AD_ACCOUNT_SELECTED],
      handleGetAudiences,
    ),
    takeLatest(PROJECT_SELECTED, handleFetchProjectMedia),
    takeLatest(UPLOAD_VIDEO, handleUploadVideo),
    takeLatest(FETCH_PROJECT_JOBS, handleFetchProjectJobs),
    takeLatest(START_JOBS_REFRESH, handleJobsRefresh),
    takeEvery(STOP_JOB, handleStopJob),
    takeLatest(GRANT_CUSTOM_AUDIENCES, handleGrantCustomAudiences),
    takeLatest(LAUNCH_GOOGLE_ADS, handleLaunch),
    takeEvery(GET_FACEBOOK_COMPLETIONS, handleGetCompletions),
    takeLatest(PROJECT_SELECTED, handleCompletionsBuffer),
    takeLatest(FETCH_SESSIONS, handleFetchSessions),
    takeLatest(CREATE_SESSION, handleCreateSession),
    takeLatest(DELETE_SESSIONS, handleSessionsDelete),
    takeLatest(
      [GET_PROJECT_FB_ADS_ACCOUNTS, PROJECT_SELECTED],
      handleFetchFacebookAdsAccounts,
    ),
    takeLatest(
      [GET_PROJECT_FB_PAGES, PROJECT_SELECTED],
      handleFetchProjectFacebookPages,
    ),
    takeEvery(FETCH_PROJECT_MEDIA_SUCCESS, handleAspectRatio),
    takeLatest(IMPORT_JOB_DATA, handleRouteChange),
    takeLatest(
      [FORM_SELECTED, PROJECT_SELECTED, ITERATION_UPDATED],
      handleStatus,
    ),
    takeLatest(LAUNCH_BUDGET_OPT, handleOptimizationLaunch),
    takeLatest(GET_PROJECT_AUTO_SCALING_SETTING, handleGetAutoScalingSetting),
    takeLatest(UPDATE_AUTO_SCALING_SETTING, handleUpdateAutoScaling),
  ]);
}
