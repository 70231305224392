import { errorHandler } from './utils';

export default client => {
  const status = () => client.get('api/status').then(({ data }) => data);

  const upload = (form, onUploadProgress) =>
    client
      .post('api/media_objects', form, { onUploadProgress })
      .then(({ data }) => data)
      .catch(errorHandler);

  const deleteMedia = id =>
    client
      .delete(`api/media_objects/${id}`)
      .then(({ data }) => data)
      .catch(errorHandler);

  const facebookStatus = payload =>
    client
      .post(`api/facebook/campaign/strategies/status`, payload)
      .then(({ data }) => data)
      .catch(errorHandler);

  const launchStrategyOne = adsData =>
    client
      .post('api/facebook/campaign/strategy/one', adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const launchStrategyOneThousand = adsData =>
    client
      .post('api/facebook/campaign/strategy/one-thousand', adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const launchStrategyThree = adsData =>
    client
      .post('api/facebook/campaign/strategy/three', adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const getExternalProject = id =>
    client.get(`api/external_projects/${id}`).then(({ data }) => data);

  const grantPermissions = facebookAdsAccount =>
    client
      .post(`api/facebook/permissions`, { facebookAdsAccount })
      .then(({ data }) => data)
      .catch(errorHandler);

  const customAudiences = () =>
    client
      .get(`api/facebook_custom_audiences`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const facebookAdsAccountAudiences = id =>
    client
      .get(`api/facebook_ads_accounts/${id}/facebook_custom_audiences`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const facebookAdsAccountMedia = id =>
    client
      .get(`api/facebook_ads_accounts/${id}/media_objects`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const grantCustomAudiences = facebookAdsAccount =>
    client
      .post(`api/facebook/custom-audiences`, { facebookAdsAccount })
      .then(({ data }) => data)
      .catch(errorHandler);

  const facebookPages = () =>
    client
      .get('api/facebook_pages')
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const completions = project =>
    client
      .post('api/facebook/openai/completions', { project })
      .then(({ data }) => data)
      .catch(errorHandler);

  const sessions = params =>
    client
      .get('api/sessions', { params })
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const createSession = (email, session) =>
    client
      .post('api/sessions', { email, data: session })
      .then(({ data }) => data)
      .catch(errorHandler);

  const deleteSessions = email =>
    client
      .post('api/sessions/delete', { email })
      .then(({ data }) => data)
      .catch(errorHandler);

  const campaignOptimization = params =>
    client
      .post('api/facebook/campaign/strategy/cbo', params)
      .then(({ data }) => data)
      .catch(errorHandler);

  const updateAutoScalingSetting = (id, payload) =>
    client
      .patch(`api/auto_scaling_settings/${id}`, payload, {
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      .then(({ data }) => data)
      .catch(errorHandler);

  return {
    status,
    upload,
    deleteMedia,
    facebookStatus,
    launchStrategyOne,
    launchStrategyOneThousand,
    launchStrategyThree,
    getExternalProject,
    grantPermissions,
    customAudiences,
    facebookAdsAccountAudiences,
    facebookAdsAccountMedia,
    grantCustomAudiences,
    facebookPages,
    completions,
    sessions,
    createSession,
    deleteSessions,
    campaignOptimization,
    updateAutoScalingSetting,
  };
};
