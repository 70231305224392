export const APP_INIT = 'APP_INIT';

export const PROJECT_SELECTED = 'PROJECT_SELECTED';
export const FORM_SELECTED = 'FORM_SELECTED';

export const USER_DATA_UPDATED = 'USER_DATA_UPDATED';
export const DAILY_BUDGET_UPDATED = 'DAILY_BUDGET_UPDATED';
export const AUDIENCE_SIZE_UPDATED = 'AUDIENCE_SIZE_UPDATED';
export const ITERATION_UPDATED = 'ITERATION_UPDATED';
export const CAMPAIGN_SETTINGS_UPDATED = 'CAMPAIGN_SETTINGS_UPDATED';

export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';

export const SET_PRESET = 'SET_PRESET';

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_FAILED = 'UPLOAD_MEDIA_FAILED';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILED = 'UPLOAD_VIDEO_FAILED';

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED';

export const FETCH_PROJECT_MEDIA = 'FETCH_PROJECT_MEDIA';
export const FETCH_PROJECT_MEDIA_SUCCESS = 'FETCH_PROJECT_MEDIA_SUCCESS';
export const FETCH_PROJECT_MEDIA_FAILED = 'FETCH_PROJECT_MEDIA_FAILED';

export const LAUNCH_ADS = 'LAUNCH_ADS';
export const LAUNCH_ADS_SUCCESS = 'LAUNCH_ADS_SUCCESS';
export const LAUNCH_ADS_FAILED = 'LAUNCH_ADS_FAILED';

export const LAUNCH_GOOGLE_ADS = 'LAUNCH_GOOGLE_ADS';
export const LAUNCH_GOOGLE_ADS_SUCCESS = 'LAUNCH_GOOGLE_ADS_SUCCESS';
export const LAUNCH_GOOGLE_ADS_FAILED = 'LAUNCH_GOOGLE_ADS_FAILED';

export const GET_EXTERNAL_PROJECT = 'GET_EXTERNAL_PROJECT';
export const GET_EXTERNAL_PROJECT_SUCCESS = 'GET_EXTERNAL_PROJECT_SUCCESS';
export const GET_EXTERNAL_PROJECT_FAILED = 'GET_EXTERNAL_PROJECT_FAILED';

export const IMPORT_PROJECT = 'IMPORT_PROJECT';
export const IMPORT_PROJECT_SUCCESS = 'IMPORT_PROJECT_SUCCESS';
export const IMPORT_PROJECT_FAILED = 'IMPORT_PROJECT_FAILED';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED';

export const GRANT_PERMISSIONS = 'GRANT_PERMISSIONS';
export const GRANT_PERMISSIONS_SUCCESS = 'GRANT_PERMISSIONS_SUCCESS';
export const GRANT_PERMISSIONS_FAILED = 'GRANT_PERMISSIONS_FAILED';

export const GRANT_CUSTOM_AUDIENCES = 'GRANT_CUSTOM_AUDIENCES';
export const GRANT_CUSTOM_AUDIENCES_SUCCESS = 'GRANT_CUSTOM_AUDIENCES_SUCCESS';
export const GRANT_CUSTOM_AUDIENCES_FAILED = 'GRANT_CUSTOM_AUDIENCES_FAILED';

export const DISPLAY_ARCHIVED_PROJECTS = 'DISPLAY_ARCHIVED_PROJECTS';

export const FETCH_CUSTOM_AUDIENCES = 'FETCH_CUSTOM_AUDIENCES';
export const FETCH_CUSTOM_AUDIENCES_SUCCESS = 'FETCH_CUSTOM_AUDIENCES_SUCCESS';
export const FETCH_CUSTOM_AUDIENCES_FAILED = 'FETCH_CUSTOM_AUDIENCES_FAILED';

export const GET_ACCOUNT_AUDIENCES = 'GET_ACCOUNT_AUDIENCES';
export const GET_ACCOUNT_AUDIENCES_SUCCESS = 'GET_ACCOUNT_AUDIENCES_SUCCESS';
export const GET_ACCOUNT_AUDIENCES_FAILED = 'GET_ACCOUNT_AUDIENCES_FAILED';

export const GET_ACCOUNT_MEDIA = 'GET_ACCOUNT_MEDIA';
export const GET_ACCOUNT_MEDIA_SUCCESS = 'GET_ACCOUNT_MEDIA_SUCCESS';
export const GET_ACCOUNT_MEDIA_FAILED = 'GET_ACCOUNT_MEDIA_FAILED';

export const FETCH_FACEBOOK_PAGES = 'FETCH_FACEBOOK_PAGES';
export const FETCH_FACEBOOK_PAGES_SUCCESS = 'FETCH_FACEBOOK_PAGES_SUCCESS';
export const FETCH_FACEBOOK_PAGES_FAILED = 'FETCH_FACEBOOK_PAGES_FAILED';

export const CALC_ASPECT_RATIO_SUCCESS = 'CALC_ASPECT_RATIO_SUCCESS';
export const LOAD_IMAGE_FAILED = 'LOAD_IMAGE_FAILED';

export const AD_ACCOUNT_SELECTED = 'AD_ACCOUNT_SELECTED';

export const FETCH_PROJECT_JOBS = 'FETCH_PROJECT_JOBS';
export const FETCH_PROJECT_JOBS_SUCCESS = 'FETCH_PROJECT_JOBS_SUCCESS';
export const FETCH_PROJECT_JOBS_FAILED = 'FETCH_PROJECT_JOBS_FAILED';
export const START_JOBS_REFRESH = 'START_JOBS_REFRESH';
export const STOP_JOBS_REFRESH = 'STOP_JOBS_REFRESH';
export const STOP_JOB = 'STOP_JOB';
export const STOP_JOB_SUCCESS = 'STOP_JOB_SUCCESS';
export const STOP_JOB_FAILED = 'STOP_JOB_FAILED';

export const GET_FACEBOOK_COMPLETIONS = 'GET_FACEBOOK_COMPLETIONS';
export const GET_FACEBOOK_COMPLETIONS_SUCCESS =
  'GET_FACEBOOK_COMPLETIONS_SUCCESS';
export const GET_FACEBOOK_COMPLETIONS_FAILED =
  'GET_FACEBOOK_COMPLETIONS_FAILED';

export const ADD_COMPLETION_BUFFER = 'ADD_COMPLETION_BUFFER';
export const REMOVE_COMPLETION_BUFFER = 'REMOVE_COMPLETION_BUFFER';

export const GET_BUFFER_COMPLETIONS = 'GET_BUFFER_COMPLETIONS';
export const GET_BUFFER_COMPLETIONS_SUCCESS = 'GET_BUFFER_COMPLETIONS_SUCCESS';
export const GET_BUFFER_COMPLETIONS_FAILED = 'GET_BUFFER_COMPLETIONS_FAILED';

export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAILED = 'FETCH_SESSIONS_FAILED';

export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';

export const DELETE_SESSIONS = 'DELETE_SESSIONS';
export const DELETE_SESSIONS_SUCCESS = 'DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_FAILED = 'DELETE_SESSIONS_FAILED';

export const LOAD_SESSION = 'LOAD_SESSION';

export const GET_PROJECT_FB_ADS_ACCOUNTS = 'GET_PROJECT_FB_ADS_ACCOUNTS';
export const GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS =
  'GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS';
export const GET_PROJECT_FB_ADS_ACCOUNTS_FAILED =
  'GET_PROJECT_FB_ADS_ACCOUNTS_FAILED';

export const GET_PROJECT_FB_PAGES = 'GET_PROJECT_FB_PAGES';
export const GET_PROJECT_FB_PAGES_SUCCESS = 'GET_PROJECT_FB_PAGES_SUCCESS';
export const GET_PROJECT_FB_PAGES_FAILED = 'GET_PROJECT_FB_ADS_ACCOUNTS_FAILED';

export const IMPORT_JOB_DATA = 'IMPORT_JOB_DATA';

export const GET_PROJECT_STATUS = 'GET_PROJECT_STATUS';
export const GET_PROJECT_STATUS_SUCCESS = 'GET_PROJECT_STATUS_SUCCESS';
export const GET_PROJECT_STATUS_FAILED = 'GET_PROJECT_STATUS_FAILED';

export const LAUNCH_BUDGET_OPT = 'LAUNCH_BUDGET_OPT';
export const LAUNCH_BUDGET_OPT_SUCCESS = 'LAUNCH_BUDGET_OPT_SUCCESS';
export const LAUNCH_BUDGET_OPT_FAILED = 'LAUNCH_BUDGET_OPT_FAILED';

export const GET_PROJECT_AUTO_SCALING_SETTING =
  'GET_PROJECT_AUTO_SCALING_SETTING';
export const GET_PROJECT_AUTO_SCALING_SETTING_SUCCESS =
  'GET_PROJECT_AUTO_SCALING_SETTING_SUCCESS';
export const GET_PROJECT_AUTO_SCALING_SETTING_FAILED =
  'GET_PROJECT_AUTO_SCALING_SETTING_FAILED';

export const UPDATE_AUTO_SCALING_SETTING = 'UPDATE_AUTO_SCALING_SETTING';
export const UPDATE_AUTO_SCALING_SETTING_SUCCESS =
  'UPDATE_AUTO_SCALING_SETTING_SUCCESS';
export const UPDATE_AUTO_SCALING_SETTING_FAILED =
  'UPDATE_AUTO_SCALING_SETTING_FAILED';
