import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import {
  getConfiguredCampaignSettings,
  getSelectedProjectId,
} from 'containers/App/reducer';
import {
  FETCH_PROJECTS_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  IMPORT_PROJECT_SUCCESS,
  IMPORT_PROJECT,
  IMPORT_PROJECT_FAILED,
  DISPLAY_ARCHIVED_PROJECTS,
  GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS,
  GET_PROJECT_FB_PAGES_SUCCESS,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_AUTO_SCALING_SETTING_SUCCESS,
} from 'containers/App/constants';
import { replaceAt } from 'utils/replaceAt';
import get from 'lodash/get';
import {
  STRATEGY_ONE,
  STRATEGY_PERFORMANCE,
  STRATEGY_SEARCH,
  STRATEGY_ONE_K,
  STRATEGY_THREE,
} from 'containers/AdsPilot/constants';
import { keyBy } from 'lodash';

const BASE_STATUS = ['active', 'prelaunch'];

const initialState = {
  data: [],
  importing: false,
  status: BASE_STATUS,
  facebookAdsAccounts: {},
  facebookPages: {},
  strategyStatus: {},
  autoScalingSettings: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROJECTS_SUCCESS:
      return { ...state, data: payload.data };

    case IMPORT_PROJECT:
      return { ...state, importing: true };

    case IMPORT_PROJECT_SUCCESS:
      return {
        ...state,
        data: [...state.data, payload.data],
        importing: false,
      };

    case IMPORT_PROJECT_FAILED:
      return { ...state, importing: false };

    case DISPLAY_ARCHIVED_PROJECTS:
      return {
        ...state,
        status: payload.display ? [...BASE_STATUS, 'archived'] : BASE_STATUS,
      };

    case UPDATE_PROJECT_SUCCESS: {
      const updatedProject = payload.data;
      const index = state.data.findIndex(
        project => project.uuid === updatedProject.uuid,
      );

      return index === -1
        ? state
        : { ...state, data: replaceAt(state.data, index, updatedProject) };
    }

    case GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS: {
      const { id, data } = payload;

      return {
        ...state,
        facebookAdsAccounts: { ...state.facebookAdsAccounts, [id]: data },
      };
    }

    case GET_PROJECT_FB_PAGES_SUCCESS: {
      const { id, data } = payload;

      return {
        ...state,
        facebookPages: { ...state.facebookPages, [id]: data },
      };
    }

    case GET_PROJECT_STATUS_SUCCESS: {
      return {
        ...state,
        strategyStatus: payload.data,
      };
    }

    case GET_PROJECT_AUTO_SCALING_SETTING_SUCCESS: {
      const settings = keyBy(payload.data, 'uuid');

      return {
        ...state,
        autoScalingSettings: {
          ...state.autoScalingSettings,
          [payload.id]: settings,
        },
      };
    }

    default:
      return state;
  }
};

export const getProjects = state => state.projects;
export const getProjectsData = state => getProjects(state).data;
export const isImportingProject = state => getProjects(state).importing;
export const getProjectsStatus = state => getProjects(state).status || [];

export const getStrategyStatus = state =>
  getProjects(state).strategyStatus || {};

const STATUS_MAP = {
  [STRATEGY_ONE]: {
    STANDARD: 'facebook.strategy.one.standard',
    EXTENDED: 'facebook.strategy.one.extended',
    FULL: ['facebook.strategy.one.full', 'facebook.strategy.one.cbo'],
  },
  [STRATEGY_ONE_K]: 'facebook.strategy.three', // TODO update when BE available
  [STRATEGY_THREE]: 'facebook.strategy.three',
  [STRATEGY_SEARCH]: 'google.strategy.one',
  [STRATEGY_PERFORMANCE]: 'google.strategy.five',
};

export const getTagsStatusMessage = createSelector(
  getStrategyStatus,
  getConfiguredCampaignSettings,
  (strategyStatus, settings) => strategy => {
    const match = get(
      STATUS_MAP,
      [strategy, settings.mode],
      STATUS_MAP[strategy],
    );

    const mergedMessage =
      Array.isArray(match) &&
      !match.every(key => strategyStatus[key]?.tags.ready);

    const message = mergedMessage
      ? match
          .map(key => {
            const type = key
              .split('.')
              .pop()
              .toUpperCase();

            const msg = get(strategyStatus, [key, 'tags', 'message']);

            return `${type}: ${msg}`;
          })
          .join('\n')
      : get(strategyStatus, [match, 'tags', 'message']);

    return message;
  },
);

export const getProjectOptions = createSelector(
  getProjectsData,
  data =>
    sortBy(data, ['name', 'status']).map(project => ({
      value: project.uuid,
      text: project.name,
      externalId: project.externalId,
      description: `${project.externalId} | ${project.uuid}`,
    })),
);

export const getSelectedProject = createSelector(
  getProjectsData,
  getSelectedProjectId,
  (projects, selectedProjectId) =>
    projects.find(project => project.uuid === selectedProjectId) || null,
);

export const getFacebookAdsAccounts = state =>
  getProjects(state).facebookAdsAccounts || {};

export const getFacebookAdsAccountsOptions = createSelector(
  getFacebookAdsAccounts,
  getSelectedProjectId,
  (accounts, projectId) => {
    const projectAccounts = accounts[projectId] || [];

    return projectAccounts.map(account => ({
      text: account.externalId,
      value: account.uuid,
    }));
  },
);

export const getFacebookPages = state => getProjects(state).facebookPages || {};

export const getSelectedProjectFacebookPagesOptions = createSelector(
  getFacebookPages,
  getSelectedProjectId,
  (pages, projectId) => {
    const projectPages = pages[projectId] || [];

    return orderBy(
      projectPages.map(page => ({
        value: page['@id'],
        text: page.name,
      })),
      'text',
    );
  },
);

const MATCH_2 = new RegExp('top kickstarter inventions', 'i');
const MATCH_3 = new RegExp('newest kickstarter inventions', 'i');

export const getDefaultFacebookPages = createSelector(
  getFacebookPages,
  getSelectedProjectId,
  (pages, projectId) => {
    const projectPages = pages[projectId] || [];
    const [defaultPage] = projectPages;

    if (!defaultPage) {
      return [];
    }

    const first =
      projectPages.find(page => !page.fromBackercamp) || defaultPage;
    const second =
      projectPages.find(page => page.name.match(MATCH_2)) || defaultPage;
    const third =
      projectPages.find(page => page.name.match(MATCH_3)) || defaultPage;

    return [first, second, third].map(p => p['@id']);
  },
);

const getAutoScalingSettings = state =>
  getProjects(state).autoScalingSettings || {};

export const getAutoScalingSettingsByProjectId = createSelector(
  getAutoScalingSettings,
  getSelectedProjectId,
  (settings, projectId) => settings[projectId] || {},
);

export const getCurrentAutoScalingSettings = createSelector(
  getAutoScalingSettingsByProjectId,
  settings => Object.values(settings)[0],
);
