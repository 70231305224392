import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { ImportButton, LoadingButton } from 'components/Buttons';
import {
  adAccountSelected,
  createSession,
  deleteMedia,
  deleteSessions,
  displayArchivedProjects,
  getProjectAutoScalingSetting as getProjectAutoScalingSettingAction,
  grantCustomAudiences,
  grantPermissions,
  importProject,
  updateAutoScalingSetting,
  uploadMedia,
} from 'containers/App/actions';
import { alpha } from '@mui/material/styles';
import { getMediaOptions, isDeleting } from 'containers/App/media/reducer';
import {
  getCurrentAutoScalingSettings,
  getFacebookAdsAccountsOptions,
  getProjectsStatus,
  getSelectedProject,
  isImportingProject,
} from 'containers/App/projects/reducer';
import MediaSelector from 'components/MediaSelector';
import { FlexWrapper } from 'components/Layout';
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import WorkIcon from '@mui/icons-material/Work';
import SaveIcon from '@mui/icons-material/Save';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Dropdown from 'components/Dropdown';
import { getSessionCreatedAt, isSaving } from 'containers/App/sessions/reducer';
import { useAuth0 } from '@auth0/auth0-react';
import { fbacIRI } from 'common/resource-wrappers';
import { DashboardLink, KickstarterLink } from './Links';

const UI = {
  OTHER: 'Other',
  MEDIA: 'Media',
  UPLOAD: 'Upload',
  DELETE: 'Delete',
  IMPORT: 'Import Project',
  AD_ACCOUNT: 'FB AD Account',
  AUTO_SCALING: 'Auto Scaling',
  FREQUENCY: 'Frequency',
  LINKS: {
    TITLE: 'Quick links',
  },
  BUTTONS: {
    PERMISSIONS: 'Grant Permissions',
    AUDIENCES: 'Grant Custom Audiences',
    ARCHIVED: 'Display Archived Projects',
    SAVE: 'Save Session',
    DELETE_SESSION: 'Delete Session',
    ENABLED: 'Enabled',
    UPDATE_AUTO_SCALING: 'Update Auto Scaling',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    '& > * + *': {
      marginTop: '16px',
    },
  },
  import: {
    display: 'flex',
    '& > *:not(:last-child)': { paddingRight: theme.spacing(1) },
  },
  session: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  dropdown: {
    width: 'auto',
    minWidth: 200,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  facebookAccount: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  autoScaleForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  inlineFlex: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  textField: { minWidth: 100, flexGrow: 1 },
}));

export function Settings({
  displayArchived,
  project,
  assets,
  autoScalingSetting,
  onUpload,
  onDelete,
  onImport,
  onSave,
  onGrantPermissions,
  isDeletingMedia,
  isSavingSession,
  adsAccount,
  onAdAccountChange,
  importing,
  onGrantCustomAudiences,
  onDisplayArchivedChange,
  sessionCreatedAt,
  onSessionDelete,
  onUpdateAutoScaling,
  getProjectAutoScalingSetting,
}) {
  const classes = useStyles();
  const { user } = useAuth0();

  const [mediaToDelete, setMediaToDelete] = useState([]);

  const [newProject, setNewProject] = useState('');

  const [adAccount, setAdAccount] = useState();

  const [autoScaling, setAutoScaling] = useState({});

  const onAutoScalingChange = key => value => {
    setAutoScaling({
      ...autoScaling,
      [key]: value,
    });
  };

  useEffect(() => {
    if (autoScalingSetting) {
      const { enabled, frequency } = autoScalingSetting;

      setAutoScaling({
        enabled,
        frequency,
      });
    }
  }, [autoScalingSetting]);

  useEffect(() => {
    if (project) {
      getProjectAutoScalingSetting(project.uuid);
    }
  }, [project]);

  useEffect(() => {
    if (adsAccount.length > 0) {
      setAdAccount(adsAccount[0].value);
      onAdAccountChange(adsAccount[0].value);
    } else {
      setAdAccount();
    }
  }, [adsAccount]);

  const handleAdAccountChange = event => {
    setAdAccount(event.target.value);
    onAdAccountChange(event.target.value);
  };

  const handleMediaSelection = e => {
    setMediaToDelete(e.target.value);
  };

  const handleMediaDelete = () => {
    onDelete(mediaToDelete, project.uuid);
  };

  useEffect(() => {
    // reset selected media when assets change
    setMediaToDelete([]);
  }, [assets]);

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <FlexWrapper>
          <div className={classes.root}>
            <span className={classes.autoScaleForm}>
              <Typography variant="h5">{UI.AUTO_SCALING}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!project}
                    name="enabled"
                    checked={autoScaling.enabled || false}
                    onChange={event =>
                      onAutoScalingChange('enabled')(event.target.checked)
                    }
                  />
                }
                label={UI.BUTTONS.ENABLED}
              />
              <span className={classes.inlineFlex}>
                <TextField
                  name="frequency"
                  disabled // todo: enable when backend is ready
                  type="number"
                  label={UI.FREQUENCY}
                  variant="outlined"
                  value={autoScaling.frequency || 24}
                  onChange={event =>
                    onAutoScalingChange('frequency')(event.target.value)
                  }
                  onWheel={event => event.target.blur()}
                  inputProps={{ min: 0 }}
                />
                <LoadingButton
                  disabled={!project}
                  color="secondary"
                  startIcon={<AutoGraphIcon />}
                  onClick={() =>
                    onUpdateAutoScaling(autoScalingSetting.uuid, autoScaling)
                  }
                >
                  {UI.BUTTONS.UPDATE_AUTO_SCALING}
                </LoadingButton>
              </span>
            </span>
            {project && (
              <>
                <Typography variant="h5">{UI.LINKS.TITLE}</Typography>
                <DashboardLink project={project} />
                <KickstarterLink project={project} />
              </>
            )}
          </div>
          <span>
            <Typography variant="h5">{UI.MEDIA}</Typography>
            <span className={classes.facebookAccount}>
              <Dropdown
                disabled={!project}
                className={classes.dropdown}
                id="ad-account-select"
                label={UI.AD_ACCOUNT}
                options={adsAccount}
                value={adAccount || ''}
                onChange={handleAdAccountChange}
              />
              <LoadingButton
                color="secondary"
                disabled={!project || !adAccount}
                onClick={() => onGrantPermissions(adAccount)}
                style={{ width: 'fit-content' }}
              >
                {UI.BUTTONS.PERMISSIONS}
              </LoadingButton>
              <LoadingButton
                color="secondary"
                startIcon={<WorkIcon />}
                disabled={!project}
                onClick={() => onGrantCustomAudiences(fbacIRI(adAccount))}
                style={{ width: 'fit-content' }}
              >
                {UI.BUTTONS.AUDIENCES}
              </LoadingButton>
            </span>
            <MediaSelector
              allowUpload
              value={mediaToDelete}
              items={assets}
              onChange={handleMediaSelection}
              onFilesDrop={files =>
                onUpload({ project, files, facebookAdsAccount: adAccount })
              }
            />
            <LoadingButton
              disabled={!project || mediaToDelete.length === 0}
              component="span"
              onClick={handleMediaDelete}
              fullWidth
              loading={isDeletingMedia}
              color="secondary"
            >
              {UI.DELETE}
            </LoadingButton>
          </span>
        </FlexWrapper>
      </div>
      <Typography variant="h5">{UI.OTHER}</Typography>
      <div className={classes.root}>
        <FormControlLabel
          control={
            <Checkbox
              name="archived"
              checked={displayArchived}
              onChange={event => onDisplayArchivedChange(event.target.checked)}
            />
          }
          label={UI.BUTTONS.ARCHIVED}
        />
        <span className={classes.import}>
          <TextField
            type="number"
            label={UI.IMPORT}
            id="import"
            variant="outlined"
            value={newProject}
            onChange={e => setNewProject(Number(e.target.value))}
            disabled={importing}
          />
          <ImportButton
            loading={importing}
            disabled={!newProject || importing}
            onClick={() => onImport(newProject)}
          />
        </span>
        <div style={{ display: 'flex', gap: '4px' }}>
          <LoadingButton
            color="secondary"
            startIcon={<SaveIcon />}
            onClick={onSave}
            style={{ width: 'fit-content' }}
            loading={isSavingSession}
          >
            <Tooltip title={`Last session created at: ${sessionCreatedAt}`}>
              <span>{UI.BUTTONS.SAVE}</span>
            </Tooltip>
          </LoadingButton>
          <LoadingButton
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onSessionDelete({ email: user.email })}
            style={{ width: 'fit-content' }}
          >
            {UI.BUTTONS.DELETE_SESSION}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  project: PropTypes.object,
  assets: PropTypes.array.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onGrantPermissions: PropTypes.func.isRequired,
  onAdAccountChange: PropTypes.func.isRequired,
  onUpdateAutoScaling: PropTypes.func.isRequired,
  isDeletingMedia: PropTypes.bool.isRequired,
  adsAccount: PropTypes.array,
  importing: PropTypes.bool,
  displayArchived: PropTypes.bool,
  isSavingSession: PropTypes.bool,
  onGrantCustomAudiences: PropTypes.func.isRequired,
  onDisplayArchivedChange: PropTypes.func.isRequired,
  sessionCreatedAt: PropTypes.string,
  onSessionDelete: PropTypes.func.isRequired,
  getProjectAutoScalingSetting: PropTypes.func.isRequired,
  autoScalingSetting: PropTypes.object,
};

const mapStateToProps = state => ({
  assets: getMediaOptions(state),
  project: getSelectedProject(state),
  isDeletingMedia: isDeleting(state),
  adsAccount: getFacebookAdsAccountsOptions(state),
  importing: isImportingProject(state),
  sessionCreatedAt: getSessionCreatedAt(state),
  isSavingSession: isSaving(state),
  displayArchived: getProjectsStatus(state).includes('archived'),
  autoScalingSetting: getCurrentAutoScalingSettings(state),
});

const mapDispatchToProps = {
  onUpload: uploadMedia,
  onDelete: deleteMedia,
  onImport: importProject,
  onGrantPermissions: grantPermissions,
  onAdAccountChange: adAccountSelected,
  onGrantCustomAudiences: grantCustomAudiences,
  onDisplayArchivedChange: displayArchivedProjects,
  onSave: createSession,
  onSessionDelete: deleteSessions,
  onUpdateAutoScaling: updateAutoScalingSetting,
  getProjectAutoScalingSetting: getProjectAutoScalingSettingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
