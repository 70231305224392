import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  getProjectAutoScalingSettingSuccess,
  getProjectAutoScalingSettingFailed,
} from '../actions';

export default function* handleGetAutoScalingSetting({ payload }) {
  try {
    const data = yield call(projectsAPI.projectAutoScalingSetting, payload.id);

    yield put(getProjectAutoScalingSettingSuccess(payload.id, data));
  } catch (e) {
    yield put(getProjectAutoScalingSettingFailed(e));
  }
}
