import { errorHandler } from './utils';

const PATH = 'api/projects';

export default client => {
  const projects = params =>
    client
      .get(PATH, { params })
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const getProjectsByPage = (params, page = 1) =>
    client.get(PATH, { params: { ...params, page } }).then(({ data }) => data);

  const getAllProjects = async (params, page = 1, acc = 0) => {
    try {
      const data = await getProjectsByPage(params, page);
      const total = data['hydra:totalItems'];
      const currentProjects = data['hydra:member'];

      const totalDownloaded = acc + currentProjects.length;

      return total > totalDownloaded
        ? [
            ...currentProjects,
            ...(await getAllProjects(params, page + 1, totalDownloaded)),
          ]
        : currentProjects;
    } catch (error) {
      return errorHandler(error);
    }
  };

  const projectMedia = id =>
    client
      .get(`${PATH}/${id}/media_objects`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const importProject = externalId =>
    client
      .post(PATH, { externalId })
      .then(({ data }) => data)
      .catch(errorHandler);

  const updateProject = id =>
    client
      .patch(
        `${PATH}/${id}`,
        {},
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      .then(({ data }) => data)
      .catch(errorHandler);

  const projectFacebookAdsAccounts = id =>
    client
      .get(`${PATH}/${id}/facebook_ads_accounts`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const projectJobs = id =>
    client
      .get(`${PATH}/${id}/jobs`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const projectFacebookPages = id =>
    client
      .get(`${PATH}/${id}/facebook_pages`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  const projectAutoScalingSetting = id =>
    client
      .get(`${PATH}/${id}/auto_scaling_setting`)
      .then(({ data }) => data['hydra:member'])
      .catch(errorHandler);

  return {
    projects,
    projectMedia,
    importProject,
    updateProject,
    projectFacebookAdsAccounts,
    projectJobs,
    projectFacebookPages,
    getAllProjects,
    projectAutoScalingSetting,
  };
};
