import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import makeStyles from '@mui/styles/makeStyles';
import Slide from '@mui/material/Slide';
import {
  getAlertsDescription,
  getAlertsMessage,
  getAlertsSeverity,
  isOpen,
} from './reducer';
import { dismissAlert } from './actions';
import SEVERITIES from './severities';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert variant="filled" {...props} ref={ref} />
));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const POSITION = {
  vertical: 'top',
  horizontal: 'center',
};

export function Alerts({ open, message, description, severity, dismiss }) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dismiss();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={POSITION}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={Slide}
      >
        <Alert onClose={handleClose} severity={severity}>
          <AlertTitle>{message}</AlertTitle>
          {description}
        </Alert>
      </Snackbar>
    </div>
  );
}

Alerts.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  description: PropTypes.string,
  severity: PropTypes.oneOf(SEVERITIES),
  dismiss: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  message: getAlertsMessage(state),
  description: getAlertsDescription(state),
  severity: getAlertsSeverity(state),
  open: isOpen(state),
});

const mapDispatchToProps = {
  dismiss: dismissAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Alerts);
